




















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Header from '@/types/tables';
import { getCustomers, setAutoanswer } from '@/api';
import { Customer, DetailedCustomer } from '@/types/customers';
import { abillsModule } from '@/store';
import { Recipients } from '@/types/broadcast';

@Component({name: 'AddAutoanswerForm'})
export default class AddAutoanswerForm extends Vue {
    loading = false;
    dialog = false;
    recipientsArray: Recipients[] = [];
    editedIndex = -1;
    editedItem: Recipients = {
        gid: 0,
        street: '',
        building: '',
    };
    defaultItem: Recipients = {
        gid: 0,
        street: '',
        building: '',
    };
    search = '';
    headers = [
        {text: 'Группа', value: 'gid'},
        {text: 'Улица', value: 'street'},
        {text: 'Дом', value: 'building'},
        {text: 'Действия', value: 'action', sortable: false},
    ];

    get formTitle() {
        return this.editedIndex === -1 ? 'Добавить адрес в автоответчик' : 'Редактировать адрес в автоответчике';
    }

    get streets() {
        return abillsModule.streets;
    }

    get groups() {
        return abillsModule.groups;
    }

    get groupsObject() {
        return this.groups.reduce((acc, cur) => ({...acc, [cur.gid]: cur.name}), {});
    }

    async created() {
        await abillsModule.fetch();
    }

    @Watch('dialog')
    onDialogChanged(val: boolean) {
        val || this.closeDialog();
    }

    editItem(item: Recipients) {
        this.editedIndex = this.recipientsArray.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.dialog = true;
    }

    deleteItem(item: Recipients) {
        const index = this.recipientsArray.indexOf(item);
        confirm('Вы уверены что хотите удалить этих получателей?') && this.recipientsArray.splice(index, 1);
    }

    closeDialog() {
        this.dialog = false;
        setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
        }, 300);
    }

    save() {
        if (this.editedIndex > -1) {
            Object.assign(this.recipientsArray[this.editedIndex], this.editedItem);
        } else {
            this.recipientsArray.push(this.editedItem);
        }
        this.closeDialog();
    }

    async add() {
        this.loading = true;
        try {
            await setAutoanswer(this.recipientsArray);
            this.$router.push({name: 'autoanswer_settings'})
        }
        finally {
            this.loading = false;
        }
    }
}
